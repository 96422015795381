<template>
  <nuxt-link
    :to="url"
    :prefetch="false"
    class="flex items-center h-40 px-8 rounded-xl gap-4 hover:bg-interaction-hover product-nav-item"
  >
    <span
      class="h-24 rounded-lg flex items-center px-[.6rem] bg-neutral-variant-2 text-brand-primary text-xs font-bold"
    >{{ productType }}</span>
    <s-tooltip
      arrow
      :content="`<span class='whitespace-nowrap'>${product.productName}</span>`"
      :duration="0"
      :distance="4"
      useFlip
      flipOnUpdate
      placement="bottom-start"
      trigger="mouseenter focus"
      :theme="'studio-tooltip'"
      :zIndex="2501"
      :allowHTML="true"
      :class="'truncate'"
    >
      <template #target>
        <span class="text-md font-medium leading-sm text-on-surface-elevation-2 pr-[0.1rem]">{{
          product.productName
        }}</span>
      </template>
    </s-tooltip>
  </nuxt-link>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { PRODUCT_DETAIL_TYPE, PRODUCT_TYPE } from '@/constants/products.const';
import { Confirmation } from '@/enums/common.enum';
import { useUserStore } from '@/stores/user.store';
import type { ProductRelationResponse } from '@/types/product/product-response.type';
import { capitalizeFirstLetter } from '@/utils/string.util';

const i18n = useI18n();
const props = defineProps<{
  product: ProductRelationResponse;
}>();

const route = useRoute();
const productId = ref(route.params.productId);
const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);

const url = computed(() => {
  const { path } = route;
  const productUrl = `/products/${productId.value}`;
  const productUrlIndex = path.indexOf(productUrl);
  if (productUrlIndex === -1) {
    return '';
  }
  return `/${i18n.locale.value}/${selectedGroupId.value}/projects/${props.product.projectId}/products/${props.product.productNo}`;
});

const productType = computed(() => {
  const { demoYn, productType, productDetailType } = props.product;
  if (demoYn === Confirmation.YES) {
    return PRODUCT_TYPE.DEMO;
  }
  if (productDetailType === PRODUCT_DETAIL_TYPE.BASIC || !productDetailType) {
    return capitalizeFirstLetter(productType);
  }

  return productDetailType;
});
</script>

<style lang="scss" scoped>
.product-nav-item {
  span:first-child {
    flex-shrink: 0;
  }
}
.product-nav-item.router-link-active {
  @apply bg-interaction-selected hover:bg-interaction-selected;
}
</style>

<template>
  <st-product-head />
  <div class="flex gap-24 mt-40">
    <div class="flex-1 relative">
      <nuxt-page />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import stProductHead from '@/components/common/st-product-head/index.vue';
import { showAlert, showConfirm } from '@/composables/useDialog';
import { ERROR_CODE, PRODUCT_FEAT_DISABLED } from '@/constants/error.const';
import { PRODUCT_LAUNCH_SETTING_PAGE_URL } from '@/constants/url.const';
import { redirectTo } from '@/utils/common.util';

definePageMeta({
  middleware: [
    'check-product-readable-middleware',
    'check-invalid-product-middleware',
    'check-product-type-middleware'
  ]
});

const route = useRoute();
const { t } = useI18n();

const { error } = route.query;
const init = async () => {
  if (error === ERROR_CODE.DISCOUNT_PRE_RELEASED_ACCESS) {
    await showAlert({
      content: t('studio.discount.verify_not_passed.cannot_access_msg')
    });
  }
  if (error === PRODUCT_FEAT_DISABLED) {
    const result = await showConfirm({
      content: t('studio.prj_prod.this_prod.home_in_app_pur_cond_type_pc_set_n'),
      confirmLabel: t('studio.prj_prod.this_prod.home_in_app_pur_cond_type_pc_set_n_to_go'),
      cancelLabel: t('studio.common.popup_case_close_btn'),
      cancelVariant: 'outline'
    });
    if (result) {
      return await redirectTo(PRODUCT_LAUNCH_SETTING_PAGE_URL);
    }
  }
};
init();
</script>

<template>
  <!-- ProductHeadComponent -->
  <div
    :class="{
      'pt-[3rem] bg-background-variant-1 sticky top-[6.8rem] z-[25] -mx-12 px-12': isSticky,
      'mt-[3rem]': !isSticky
    }"
  >
    <div class="relative flex items-start">
      <div class="flex gap-[.6rem] items-center mb-24 flex-1">
        <span
          v-if="pageTitles.length <= 1"
          class="text-xs text-on-surface-elevation-3 font-regular leading-xs cursor-pointer hover:underline"
          @click="redirectTo(PROJECT_MNG_PAGE_URL)"
        >
          {{ $t('studio.lnb.common_2depth_product_management') }}
        </span>
        <s-icon
          v-if="pageTitles.length <= 1"
          class="text-on-surface-elevation-3"
          icon="ic-v2-control-arrow-right-line"
          size="xs"
        />
        <nuxt-link
          v-if="pageTitles.length <= 1"
          class="product-home-url text-xs text-on-surface-elevation-3 font-regular leading-xs cursor-pointer pr-2"
          :to="productHomeUrl()"
        >
          {{ $t('studio.gnb.common_1depth_product_home') }}
        </nuxt-link>
        <s-icon
          v-if="showFeatMenu && pageTitles.length <= 1"
          icon="ic-v2-control-arrow-right-line"
          size="xs"
          class="text-on-surface-elevation-3"
        />
        <st-product-feats-navigator v-if="showFeatMenu" :currentFeatName="currentFeatName" />
        <template v-for="(pageTitle, index) in pageTitles" :key="index">
          <s-icon
            v-if="index > 0"
            icon="ic-v2-control-arrow-right-line"
            size="xs"
            class="text-on-surface-elevation-3"
          />
          <nuxt-link
            v-if="index > 0"
            class="text-xs text-on-surface-elevation-2 font-regular leading-xs"
            :to="pageTitle.url"
          >
            {{ $t(pageTitle.title || '') }}
          </nuxt-link>
          <!-- <span
            v-if="index > 0"
            class="text-xs text-on-surface-elevation-2 font-regular leading-xs"
          >
            {{ $t(pageTitle.title || '') }}
          </span> -->
        </template>
      </div>
      <div v-show="showProductKey" class="relative shrink-0 -mt-[.6rem]">
        <st-product-key-selection />
      </div>
      <div v-if="showProductSearch" class="absolute right-0 top-[-1rem] w-[22.5rem]">
        <st-search-layer
          :name="'productSearchLayer'"
          :placeholder="$t('studio.prj_prod.this_prod.home_search_place_holder')"
          :searchLayerId="uuid"
          :class="'custom-dropdown'"
          :showLayer="showLayer"
          @search="onSearch"
          @clear="onSearch('')"
        >
          <template v-if="products.length === 0 && keyword.length > 0">
            <div class="w-full h-80 flex items-center justify-center">
              <s-text as="span" role="text3" class="text-on-surface-elevation-2">
                {{ $t('studio.prj_prod.this_project.this_product.home_search_nmrf_msg') }}
              </s-text>
            </div>
          </template>
          <template v-else>
            <div class="flex flex-col gap-8">
              <s-text
                v-if="!keyword"
                as="span"
                role="text3"
                class="text-on-surface-elevation-4 px-16"
              >
                {{ $t('studio.prj_prod.this_prod.home_search_recent') }}
              </s-text>
              <div class="flex flex-col">
                <span
                  v-for="(product, index) in keyword ? products : searchedProducts"
                  :key="index"
                  class="flex items-center hover:bg-interaction-hover active:bg-interaction-pressed px-24 truncate"
                >
                  <s-tooltip
                    arrow
                    :content="product.productName"
                    :duration="0"
                    useFlip
                    flipOnUpdate
                    placement="bottom"
                    trigger="mouseenter focus"
                    :zIndex="600"
                    class="truncate"
                  >
                    <template #target>
                      <button
                        type="button"
                        class="flex-1 text-md leading-lg font-regular text-on-surface-elevation-2 text-left py-8 truncate w-full"
                        :class="
                          Number(productId) === product.productNo ? '!text-brand-primary' : ''
                        "
                        @click="gotoProductHome(product)"
                      >
                        {{ product.productName }}
                      </button>
                    </template>
                  </s-tooltip>
                  <button
                    v-if="!keyword"
                    class="shrink-0 ml-auto"
                    @click="removeRecentSearch(index)"
                  >
                    <s-icon
                      icon="ic-v2-control-close-line"
                      size="xl"
                      class="text-on-surface-elevation-4"
                    />
                  </button>
                </span>
              </div>
            </div>
          </template>
        </st-search-layer>
      </div>
    </div>
    <div v-if="pageTitles.length <= 1" class="flex gap-4 items-center mb-8">
      <st-product-pages-navigator />
      <i class="ml-8 mr-12 w-[.1rem] h-[1.8rem] block bg-inverse-elevation-2"></i>
      <span
        class="rounded-lg h-24 px-[.6rem] leading-xs bg-blue50 flex items-center text-xs font-bold text-brand-primary"
      >
        {{ productDetailLabel }}
      </span>
      <span class="text-xl leading-sm font-medium text-on-surface-elevation-1 ml-4 break-all">
        {{ productName }}
      </span>
    </div>
    <div class="flex gap-8 items-center">
      <p class="text-5xl text-on-surface-elevation-1 font-bold leading-4xl flex-1">
        {{ $t(pageTitles[pageTitles.length - 1]?.title ?? '') }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { searchProductsApi } from '@/apis/products.api';
import StProductFeatsNavigator from '@/components/common/st-product-head/st-product-feats-navigator.vue';
import StProductPagesNavigator from '@/components/common/st-product-head/st-product-pages-navigator.vue';
import StProductKeySelection from '@/components/common/st-product-head/st-product-selection.vue';
import StSearchLayer from '@/components/common/st-search-layer.vue';
import { useApp } from '@/composables/useApp';
import { PRODUCT_HOME_PAGE_URL, PROJECT_MNG_PAGE_URL } from '@/constants/url.const';
import useProductStore from '@/stores/product.store';
import { useUserStore } from '@/stores/user.store';
import type { ProductSearchItem, SearchedProduct } from '@/types/product/product-response.type';
import { generateProductUrl, redirectTo } from '@/utils/common.util';
import { generateUUID } from '@/utils/uuid.util';

const i18n = useI18n();
const productStore = useProductStore();
const userStore = useUserStore();
const route = useRoute();

const { selectedGroupId } = storeToRefs(userStore);
const {
  productName,
  productDetailLabel,
  showProductKey,
  showProductSearch,
  showFeatMenu,
  pageTitles,
  isSticky
} = storeToRefs(productStore);

const isLoading = ref(false);
const { checkProductPermission } = useApp();
const uuid: string = `search_layer_${generateUUID()}`;
const { productId } = route.params;

const products = ref<ProductSearchItem[]>([]);
const keyword = ref('');
const searchedProducts = ref<SearchedProduct[]>([]);

const showLayer = computed(
  () =>
    (products.value.length > 0 || searchedProducts.value.length > 0 || keyword.value.length > 0) &&
    !isLoading.value
);

const removeRecentSearch = (index: number) => {
  const searchedProductsStr = localStorage.getItem('searchedProducts');
  const savedProducts = searchedProductsStr
    ? (JSON.parse(searchedProductsStr) as SearchedProduct[])
    : [];
  savedProducts.splice(index, 1);
  localStorage.setItem('searchedProducts', JSON.stringify(savedProducts));
  searchedProducts.value = savedProducts;
};

const gotoProductHome = async (product: ProductSearchItem | SearchedProduct) => {
  if (
    !(await checkProductPermission({ productId: product.productNo.toString(), checkType: 'read' }))
  ) {
    return;
  }
  if (String(productId) === String(product.productNo)) {
    return;
  }
  const productItem = {
    groupId: (product as SearchedProduct).groupId || selectedGroupId.value,
    projectId: product.projectId,
    productNo: product.productNo,
    productName: product.productName
  };

  const foundIndex = searchedProducts.value.findIndex(
    (item: SearchedProduct) =>
      item.productNo === product.productNo && item.projectId === product.projectId
  );

  if (foundIndex === -1) {
    if (searchedProducts.value.length >= 5) {
      searchedProducts.value.pop();
    }
    searchedProducts.value.unshift(productItem);
  }
  if (foundIndex > -1) {
    searchedProducts.value.splice(foundIndex, 1);
    searchedProducts.value.unshift(productItem);
  }

  localStorage.setItem('searchedProducts', JSON.stringify(searchedProducts.value));
  const searchedProduct = product as SearchedProduct;

  let external = false;
  const groupId = searchedProduct.groupId || selectedGroupId.value;
  if (groupId !== selectedGroupId.value) {
    external = true;
  }

  redirectTo(PRODUCT_HOME_PAGE_URL, {
    projectId: searchedProduct.projectId,
    productId: searchedProduct.productNo,
    groupId,
    external
  });
};

const onSearch = async (kw: string) => {
  keyword.value = kw;
  products.value = [];
  if (!kw) {
    return;
  }
  isLoading.value = true;
  const result = await searchProductsApi(
    {
      q: kw,
      groupId: selectedGroupId.value,
      page: 1,
      size: 999999
    },
    false
  );
  products.value = result?.contents ?? [];
  isLoading.value = false;
};

const currentFeatName = computed<string>(() => {
  const { matched } = route;
  for (const item of matched) {
    if (item.meta.pageTitle) {
      return item.meta.pageTitle as string;
    }
  }
  return pageTitles.value[pageTitles.value.length - 1]?.title ?? '';
});

const productHomeUrl = () => {
  return `/${i18n.locale.value}${generateProductUrl(PRODUCT_HOME_PAGE_URL)}`;
};

const getSearchedProducts = () => {
  const searchedProductsStr = localStorage.getItem('searchedProducts');
  const result = searchedProductsStr ? (JSON.parse(searchedProductsStr) as SearchedProduct[]) : [];
  return result;
};

searchedProducts.value = getSearchedProducts();
</script>
<style lang="scss" scoped>
.product-home-url:not(.router-link-exact-active) {
  @apply hover:underline;
}
</style>

import { onMounted, onUnmounted, ref } from 'vue';

export function useCopyToClipboard() {
  const showCopiedMessage = ref(false);
  const copyButtonRef = ref<HTMLButtonElement | null>(null);
  let hideTimeout: ReturnType<typeof setTimeout> | null = null;

  const copyToClipboard = async (text: string) => {
    if (!text) {
      return;
    }

    if (hideTimeout) {
      clearTimeout(hideTimeout);
    }

    if ('clipboard' in navigator && 'writeText' in navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(text);
        showCopiedMessage.value = true;
        startHideTimer();
      } catch (err) {
        // console.error('Failed to copy: ', err);
      }
    } else {
      // Fallback for older browsers
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        showCopiedMessage.value = true;
        startHideTimer();
      } catch (err) {
        // console.error('Failed to copy:', err);
      }
      document.body.removeChild(textArea);
    }
  };

  const startHideTimer = () => {
    hideTimeout = setTimeout(() => {
      showCopiedMessage.value = false;
    }, 3000);
  };

  const hideCopiedMessage = () => {
    if (hideTimeout !== null) {
      clearTimeout(hideTimeout);
      hideTimeout = null;
    }
    showCopiedMessage.value = false;
  };

  const handleGlobalClick = (event: MouseEvent) => {
    if (copyButtonRef.value && !copyButtonRef.value.contains(event.target as Node)) {
      hideCopiedMessage();
    }
  };

  onMounted(() => {
    document.addEventListener('click', handleGlobalClick);
  });

  onUnmounted(() => {
    document.removeEventListener('click', handleGlobalClick);
    if (hideTimeout !== null) {
      clearTimeout(hideTimeout);
    }
  });

  return {
    showCopiedMessage,
    copyButtonRef,
    copyToClipboard
  };
}

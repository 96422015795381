<template>
  <s-contents-popup
    :key="route.fullPath"
    :distance="8"
    :offset="[0, 0]"
    placement="bottom-start"
    trigger="click"
    :minWidth="448"
    :maxWidth="448"
    :flipOnUpdate="false"
  >
    <template #target>
      <div class="flex items-center gap-4">
        <button type="button" class="h-24 w-24" @click="handleClick">
          <s-icon
            icon="ic-v2-community-view-list-fill"
            size="4xl"
            class="text-on-surface-elevation-2 flex"
          />
        </button>
      </div>
    </template>
    <template #contents>
      <s-contents-popup-content-body
        class="studio-scrollbar-4 min-h-[22rem] max-h-[46rem] !my-0 py-24 !pr-4 w-[44.8rem]"
      >
        <div class="flex flex-col gap-20">
          <div v-for="project in projectProductItems" :key="project.id" class="project-item">
            <p class="text-xs leading-xs text-on-surface-elevation-4 font-regular mb-8">
              {{ project.name }}
            </p>
            <template v-for="product in project.products" :key="product.productNo">
              <st-product-type-item :product="product" />
              <div
                v-for="childV1 in product.children"
                :key="childV1.productNo"
                class="[&>.flex]:!pl-28 relative before:absolute before:!h-[2.4rem] before:left-12 before:inset-y-8 before:w-[.1rem] before:bg-border before:block"
              >
                <st-product-type-item :product="childV1" :productName="childV1.productName" />
                <div
                  v-for="childV2 in childV1.children"
                  :key="childV2.productNo"
                  class="[&>.flex]:!pl-56 relative before:absolute before:left-24 before:inset-y-8 before:w-[.1rem] before:bg-border before:block"
                >
                  <st-product-type-item :product="childV2" :productName="childV2.productName" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </s-contents-popup-content-body>
    </template>
  </s-contents-popup>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';

import StProductTypeItem from '@/components/common/st-product-head/st-product-type-item.vue';
import { useAppStore } from '@/stores/app.store';
import { delayTime } from '@/utils/time.util';

const appStore = useAppStore();
const { projectProductItems } = storeToRefs(appStore);

const route = useRoute();

const handleClick = async () => {
  await delayTime(100);
  const projectItems = document.getElementsByClassName('project-item');
  const activeProjectItem = Array.from(projectItems).find((item: Element) =>
    item.querySelector('.product-nav-item.router-link-active')
  );
  if (activeProjectItem) {
    activeProjectItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }
};
</script>

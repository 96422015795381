<template>
  <!-- ProductKeyListItem -->
  <dl>
    <dt class="mb-4 text-xs leading-xs text-on-surface-elevation-4 -tracking-[.02em]">
      {{ productKeyTitle }}
    </dt>
    <dd class="flex items-center gap-20">
      <p class="flex-1 text-sm leading-md text-on-surface-elevation-2 break-all -tracking-[.025em]">
        {{ productKeyValue }}
      </p>
      <div class="relative shrink-0">
        <button ref="copyButtonRef" type="button" @click="handleCopy">
          <s-icon size="3xl" icon="ic-v2-community-copy-line" class="text-on-surface-elevation-2" />
        </button>
        <div
          v-if="showCopiedMessage"
          class="absolute top-1/2 right-[calc(100%+.4rem)] -translate-y-1/2 inline-flex gap-4 px-[1rem] py-[.6rem] bg-[rgba(72,74,81,.9)] text-neutral-variant-3 rounded-full"
        >
          <s-icon size="xl" icon="ic-v2-control-check-bold-line" />
          <span class="text-xs leading-xs whitespace-nowrap">{{
            $t('studio.toast.link_copied')
          }}</span>
        </div>
        <slot></slot>
      </div>
    </dd>
  </dl>
</template>

<script setup lang="ts">
import { useCopyToClipboard } from '@/composables/useCopyToClipboard';

const props = defineProps<{
  productKeyTitle: string;
  productKeyValue: string;
}>();

const { showCopiedMessage, copyButtonRef, copyToClipboard } = useCopyToClipboard();

const handleCopy = () => {
  if (props.productKeyValue) {
    copyToClipboard(props.productKeyValue);
  }
};
</script>

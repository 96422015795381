<template>
  <div v-if="showTheGuide" class="absolute right-[calc(100%+12px)] top-[-.3rem] flex items-center gap-8 py-8 px-12 rounded-xl bg-neutral-variant-1 text-white">
    <s-text as="span" role="text4" class="font-medium w-max">{{ $t('studio.prj_prod_mngmt.rating_build_review.tooltipbox_info') }}</s-text>
    <button class="shrink-0 h-16 w-16 flex items-center" @click="closeTheGuide">
      <s-icon icon="ic-v2-control-close-line" size="xl" />
    </button>
    <i class="absolute right-[-.4rem] h-[.6rem] w-4"><img src="@/assets/images/common/tail.png" alt="" class="w-full h-full object-cover" /></i>
  </div>
  <s-contents-popup
    :distance="0"
    :offset="[0, 4]"
    placement="bottom-end"
    trigger="click"
    :minWidth="438"
    :maxWidth="438"
    :flipOnUpdate="false"
    @shown="onProductKeyLayerShown"
    @hidden="onProductKeyLayerHidden"
  >
    <template #target>
      <button ref="targetButton" class="flex items-center gap-12 h-32">
        <span
          class="overflow-hidden overflow-ellipsis whitespace-nowrap text-md leading-sm text-on-surface-elevation-3"
        >
          {{ $t('studio.prj_prod.this_prod.product_data.build.home_product_key_msg') }}
        </span>
        <s-icon
          size="xl"
          icon="ic-v2-control-select-arrow-down-fill"
          class="text-on-surface-elevation-2"
          :class="{ 'rotate-180': isOpenedProductKey }"
        />
      </button>
    </template>
    <template #contents>
      <div class="flex flex-col gap-16 p-24">
        <product-key-list-item
          :productKeyTitle="
            $t('studio.prj_prod.this_prod.product_data.build.home_product_key_tooltip_prod_id_row1')
          "
          :productKeyValue="productKeyData.productNo.toString()"
        />
        <product-key-list-item
          :productKeyTitle="
            $t('studio.prj_prod.this_prod.product_data.build.home_product_key_tooltip_game_id')
          "
          :productKeyValue="productKeyData.gameId"
        />
        <product-key-list-item
          productKeyTitle="Application Key"
          :productKeyValue="productKeyData.applicationKey"
        />
        <product-key-list-item
          productKeyTitle="Application Secret"
          :productKeyValue="productKeyData.applicationSecret"
        />
      </div>
    </template>
  </s-contents-popup>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';

import { getProductKeyInfoAPI } from '@/apis/build.api';
import ProductKeyListItem from '@/components/common/st-product-head/product-key-list-item.vue';
import useProductStore from '@/stores/product.store';
import type { ProductKeyInfo } from '@/types/build/build.type';

const productStore = useProductStore();
const { product } = storeToRefs(productStore);

const gameId = product.value?.gameId || '';

const isOpenedProductKey = ref<boolean>(false);
const showTheGuide = ref(true);
const storedGuideName = 'product-key-guide-showed';

const targetButton = ref<HTMLButtonElement | null>(null);

const onProductKeyLayerShown = () => {
  isOpenedProductKey.value = true;
};
const onProductKeyLayerHidden = () => {
  isOpenedProductKey.value = false;
};

const productKeyData = ref<ProductKeyInfo>({
  productNo: 0,
  gameNo: 0,
  gameId: '',
  applicationKey: '',
  applicationSecret: ''
});

const getProductKeyInfo = async () => {
  const params = {
    gameId
  };

  try {
    const response = await getProductKeyInfoAPI(params);
    if (response) {
      productKeyData.value = response;
    }
  } catch (error) {}
};

const init = () => {
  getProductKeyInfo();
};

onMounted(() => {
  const storedValue = localStorage.getItem(storedGuideName);

  if (storedValue) {
    showTheGuide.value = false;
  }
});

const closeTheGuide = () => {
  showTheGuide.value = false;
  localStorage.setItem(storedGuideName, '1');
};

init();
</script>

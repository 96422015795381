<template>
  <s-contents-popup
    :distance="8"
    :offset="[0, 0]"
    placement="bottom-start"
    trigger="click"
    :minWidth="526"
    :maxWidth="526"
    :flipOnUpdate="false"
    :popperOptions="undefined"
    :theme="'studio-product-menu'"
  >
    <template #target>
      <div class="flex items-center gap-4">
        <span class="text-xs text-on-surface-elevation-2 font-regular leading-xs">
          {{ $t(currentFeatName || '') }}
        </span>
        <button
          ref="menuRefButton"
          type="button"
          class="h-20 w-20 flex items-center justify-center bg-[rgba(19,85,255,.08)] border-1 border-solid border-border rounded-full"
          @click="isOpenProductMenu = !isOpenProductMenu"
        >
          <s-icon
            icon="ic-v2-control-arrow-down-line"
            size="sm"
            class="text-on-surface-elevation-1 flex"
            :class="{ 'rotate-180': isOpenProductMenu }"
          />
        </button>
      </div>
    </template>
    <template #contents>
      <s-contents-popup-content-body>
        <div class="flex flex-col gap-16">
          <div v-for="(group, groupIndex) in gnbProductFeats" :key="`group-url-${groupIndex}`">
            <nuxt-link
              v-if="group.id === PRODUCT_FEAT_GROUP_HOME"
              class="text-xs leading-xs text-on-surface-elevation-4 font-regular ml-[1rem] mb-4"
              :to="productHomeUrl()"
            >
              {{ $t('studio.gnb.common_1depth_product_home') }} >
            </nuxt-link>
            <p
              v-else
              class="text-xs leading-xs text-on-surface-elevation-4 font-regular ml-[1rem] mb-4"
            >
              {{ $t(group.name) }}
            </p>
            <div class="flex flex-wrap gap-x-8">
              <template
                v-for="(productFeat, productUrlIndex) in group.children"
                :key="`product-url-${productUrlIndex}`"
              >
                <st-product-menu-item
                  :linkValue="$t(productFeat.name)"
                  :to="
                    productFeat.internal
                      ? productStore.productFeatUrlGenerator(productFeat)
                      : undefined
                  "
                  :disabled="(productFeat.url === PRODUCT_DISCOUNT_PAGE_URL && (!isReleased && !(isVerifyPass && isPageBuild))) || productStore.isMenuDisabled(productFeat)
                  "
                  @menuClick="onClick(productFeat)"
                />
              </template>
            </div>
          </div>
        </div>
      </s-contents-popup-content-body>
    </template>
  </s-contents-popup>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import StProductMenuItem from '@/components/common/st-product-head/st-product-menu-item.vue';
import { useApp } from '@/composables/useApp';
import { PRODUCT_FEAT_GROUP_HOME } from '@/constants/page.const';
import { PRODUCT_DISCOUNT_PAGE_URL, PRODUCT_HOME_PAGE_URL } from '@/constants/url.const';
import useProductStore from '@/stores/product.store';
import type { ProductFeatChild } from '@/types/product/product-model.type';
import { generateProductUrl, redirectTo } from '@/utils/common.util';

defineProps<{
  currentFeatName?: string;
}>();

const { checkProductPermission } = useApp();

const menuRefButton = ref<any>();
const isOpenProductMenu = ref();
const i18n = useI18n();
const productStore = useProductStore();
const { gnbProductFeats, isReleased, isVerifyPass, isPageBuild } = storeToRefs(productStore);

const onClick = async (item: ProductFeatChild) => {
  menuRefButton.value.click();
  if (item.internal) {
    return;
  }
  const isReadable = await checkProductPermission({
    roleId: item.roleAuthorityId,
    checkType: 'read'
  });
  if (!isReadable) {
    return;
  }
  const generatedUrl = productStore.productFeatUrlGenerator(item);
  return redirectTo(generatedUrl, { external: true });
};

const productHomeUrl = () => {
  return `/${i18n.locale.value}${generateProductUrl(PRODUCT_HOME_PAGE_URL)}`;
};

const init = () => {
  nextTick(() => {
    const menuElm = document.querySelector('[theme="studio-product-menu"]');
    document.addEventListener('click', (event: MouseEvent) => {
      if (menuElm && !menuElm.contains(event.target as Node)) {
        isOpenProductMenu.value = false;
      }
    });
  });
};

init();
</script>

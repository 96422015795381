<template>
  <nuxt-link
    :prefetch="false"
    class="cursor-pointer product-menu-item flex items-center h-[3.8rem] px-[1rem] rounded-lg text-sm font-medium leading-md w-[calc((100%-1.6rem)/3)]"
    :class="{ 'pointer-events-none': disabled }"
    :to="url"
    @click="onClick"
  >
    <span :class="{ 'text-disabled-variant-1': disabled }">{{ linkValue }}</span>
  </nuxt-link>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { generateProductUrl } from '@/utils/common.util';

const { locale } = useI18n();
const props = defineProps<{
  linkValue: string;
  to?: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  menuClick: [];
}>();

const url = computed(() => {
  if (!props.to) {
    return undefined;
  }
  const generatedUrl = generateProductUrl(props.to);
  return `/${locale.value}${generatedUrl}`;
});

const onClick = () => {
  emit('menuClick');
};
</script>
<style lang="scss" scoped>
.product-menu-item:not(.router-link-active) {
  @apply text-on-surface-elevation-2;
}
.product-menu-item.router-link-active {
  @apply text-brand-primary bg-[rgba(19,85,255,.08)];
}
</style>
